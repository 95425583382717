<template>
  <div class="echart-div"></div>
</template>

<script>
const echarts = require('echarts')

export default {
  props: {
    data: { type: Object, defualt: () => null }
  },
  mounted() {
    this.draw()
  },
  methods: {
    getOption ({title, data, dw, color}, position) {
      let option = {
        title: {
          text: title,
          bottom: 0,
          left: 'center',
          color: '#515a6e',
          textStyle: { fontSize: 14, fontWeight: 500 }
        },
        tooltip: {
          position: position,
          trigger: 'item', formatter: function (param) {
            return param.marker + ' '+ param.name + ' ' + param.value + (dw || '')
          }
        },
        grid: { left: '0%', right: '0%', bottom: '4%', top: '0', containLabel: true },
        legend: { show: false },
        color: color,
        series: [
          {
            name: title,
            type: 'pie',
            radius: ['0%', '80%'],
            avoidLabelOverlap: false,
            label: { show: false },
            emphasis: { label: { show: false } },
            labelLine: { show: false },
            data: data
          }
        ]
      }
      return option
    },
    draw () {
      if (!this.data) {
        return
      }
      let el = this.$el
      let e = echarts.init(el)
      let option = this.getOption(this.data, [10, 10])
      e.setOption(option)
    }
  }
}
</script>

<style scoped lang="less">
  .echart-div {
    width: 100%;
    height: 100%;
  }
</style>