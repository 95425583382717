<template>
  <div class="purchase-detail-base-info">
    <div class="info-item">
      <div class="label">申报部门：</div>
      <div class="value">{{data.orgName}}</div>
    </div>
    <div class="info-item">
      <div class="label">申请人：</div>
      <div class="value">{{data.userName}}</div>
    </div>
    <div class="info-item">
      <div class="label">项目名称：</div>
      <div class="value">{{data.goodsName}}</div>
    </div>
    <div class="info-item">
      <div class="label">可选品牌及规格型号：</div>
      <div class="value">{{data.typeDetail}}</div>
    </div>
    <div class="info-item">
      <div class="label">单价：</div>
      <div class="value">{{data.price + '元'}}</div>
    </div>
    <div class="info-item">
      <div class="label">数量：</div>
      <div class="value">{{data.num}}</div>
    </div>
    <div class="info-item">
      <div class="label">总价：</div>
      <div class="value">{{data.total + '元'}}</div>
    </div>
    <!-- <div class="info-item">
      <div class="label">关联预算：</div>
      <div class="value">{{data.budgetDetailIds ? (data.budgetDetailIds.split(',').map(v => v)).join(',') : ''}}</div>
    </div> -->
    <div class="info-item">
      <div class="label">申请理由：</div>
      <div class="value">{{data.reason}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, defualt: () => null }
  }
}
</script>

<style lang="less" scoped>
.purchase-detail-base-info {
  display: flex;
  color: #515a6e;
  flex-wrap: wrap;
  align-content: flex-start;
  .info-item {
    display: flex;
    margin-right: 50px;
    .label {
      margin-right: 0px;
    }
  }
}
</style>