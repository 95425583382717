<template>
  <fm-modal title="采购申报" v-model="modal" :mask-closable="false" width="1200" v-if="modal">
    <div class="p-detial-from">
      <div class="right">
        <fm-title titleText="采购明细"></fm-title>
        <fm-form label-align="left">
          <fm-form-item label="名称">
            <GoodsSelect v-model="formData.goodsId" placeholder="名称" clearable/>
          </fm-form-item>
          <fm-form-item label="申请科室" v-if="orgList.length > 1">
            <fm-select absolute filterable :clearable="true" v-model="formData.orgId">
              <fm-option v-for="item in orgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </fm-form-item>
          <fm-form-item label="可选品牌及规格型号">
            <fm-input-new v-model="formData.typeDetail" placeholder="请填写3个及以上品牌"></fm-input-new>
          </fm-form-item>
          <fm-form-item label="数量">
            <fm-input-new @change="onChange('num')" v-model="formData.num" placeholder="数量"></fm-input-new>
          </fm-form-item>
          <fm-form-item label="单价">
            <fm-input-new @change="onChange('price')" v-model="formData.price" placeholder="单价"></fm-input-new>
            <div class="xj" v-if="goods && goods.maxPirce && goods.maxPirce < formData.price">该物品限价{{goods.maxPirce}}元</div>
          </fm-form-item>
          <fm-form-item label="总价">
            <fm-input-new v-model="formData.total" disabled placeholder="总价"></fm-input-new>
          </fm-form-item>
          <fm-form-item label="申请理由">
            <fm-input-new type="textarea" v-model="formData.reason" placeholder="申请理由"></fm-input-new>
          </fm-form-item>
        </fm-form>
        <div class="model-btn">
          <fm-btn style="margin-right: 30px;" @click="formSubmit">保存</fm-btn>
          <fm-btn @click="modal = false">取消</fm-btn>
        </div>
      </div>
      <div class="left-a">
        <div class="left">
        <fm-title titleText="关联预算明细"></fm-title>
        <div class="budget-detail-list-search">
          <fm-switch size="mini" v-model="allOrg" style="margin-right: 10px;">
            <span slot="on">本科室</span>
            <span slot="off">全部科室</span>
          </fm-switch>
          <fm-input-new style="width:100px;" size="small" placeholder="输入名称搜索" v-model="searchText"></fm-input-new>
        </div>
        <div class="budget-detail-list">
          <a href="javascript:;" @click="chooseDetail(item)" class="budget-detail-item" :class="{'budget-detail-item-a': chooseBudgetDetailIds.includes(item.id)}"  v-for="item in showBudgetDetailList" :key="item.id">
            {{item.code + ' ' + (item.budgetApplyOrgName || '') + ' ' + item.goodsName}}
          </a>
        </div>
      </div>
      <div class="center">
        <budget-detail-info type="column" :budgetDetailIds="chooseBudgetDetailIds"></budget-detail-info>
      </div>
      </div>
    </div>
  </fm-modal>
</template>

<script>
import {
  purchaseBatchDetailsRequest
} from '../../../../api'

import GoodsSelect from '../../../base/assets_type/goodsSelect'
import budgetDetailInfo from './budgetDetailInfoAdd'

export default {
  components: {
    GoodsSelect,
    budgetDetailInfo
  },
  props: {
    data: { type: Object, defualt: () => null },
    orgList: { type: Array, defualt: () => [] },
    budgetDetailList: { type: Array, defualt: () => [] },
  },
  created () {
    this.$store.dispatch('loadGoodsList')
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.formData = this.data ? JSON.parse(JSON.stringify(this.data)) : {
          total: ''
        }
        this.chooseBudgetDetailIds = this.data && this.data.budgetDetailIds ? this.data.budgetDetailIds.split(',').map(v => Number(v)) : []
      },
      immediate: true
    }
  },
  data () {
    return {
      chooseBudgetDetailIds: [],
      allOrg: true,
      formData: {
        goodsId: null,
        total: ''
      },
      searchText: '',
      step: 0,
      modal: false,
      loading: false
    }
  },
  computed: {
    goods () {
      let data = this.$store.getters.goodsList.find(v => v.data.id === this.formData.goodsId)
      console.log(data)
      return data ? data.data : null
    },
    showBudgetDetailList () {
      let allData = this.budgetDetailList
      if (this.searchText) {
        allData = allData.filter(item => (item.code + ' ' + (item.budgetApplyOrgName || '') + ' ' + item.goodsName).includes(this.searchText))
      }
      if (this.allOrg && this.myOrgId) {
        allData = allData.filter(v => v.budgetApplyOrgId === this.myOrgId)
      }
      return allData
    },
    myOrgId () {
      if (this.$store.getters.currentOrgId) {
        return this.$store.getters.currentOrgId
      } else {
        return null
      }
    }
  },
  methods: {
    chooseDetail (item) {
      if (this.chooseBudgetDetailIds.includes(item.id)) {
        this.chooseBudgetDetailIds = this.chooseBudgetDetailIds.filter(v => v !== item.id)
      } else {
        this.chooseBudgetDetailIds.push(item.id)
      }
    },
    async formSubmit () {
      if (this.loading) {
        return
      }
      if (this.formData.price && this.goods && this.goods.maxPirce && this.goods.maxPirce < Number(this.formData.price)) {
        this.$notify({
          title: '系统提示',
          message: '该物品限价' + this.goods.maxPirce +'元',
          type: 'warning'
        })
        return
      }
      if (!this.formData.goodsId) {
        this.$notify({
          title: '系统提示',
          message: '请选择名称',
          type: 'info'
        })
        return
      }
      // if (!this.formData.num) {
      //   this.$notify({
      //     title: '系统提示',
      //     message: '请输入数量',
      //     type: 'info'
      //   })
      //   return
      // }
      // if (!this.formData.price) {
      //   this.$notify({
      //     title: '系统提示',
      //     message: '请输入单价',
      //     type: 'info'
      //   })
      //   return
      // }
      // if (this.chooseBudgetDetailIds.length > 0) {
      //   let dList = this.budgetDetailList.filter(v => this.chooseBudgetDetailIds.includes(v.id))
      //   if (dList.find(v => v.goodsId !== this.formData.goodsId)) {
      //     this.$notify({
      //       title: '系统提示',
      //       message: '名称和预算明细不匹配，请重新选择',
      //       type: 'info'
      //     })
      //     return
      //   }
      // }
      if (this.chooseBudgetDetailIds.length === 0) {
        this.$notify({
          title: '系统提示',
          message: '请选择预算',
          type: 'info'
        })
        return
      }
      this.loading = true

      let data = this.formData
      if (this.orgList.length === 1 && !data.orgId) {
        data.orgId = this.orgList[0].key
      }
      data.budgetDetailIds = this.chooseBudgetDetailIds.length > 0 ? this.chooseBudgetDetailIds.join(',') : null
      if (data.id) {
        await purchaseBatchDetailsRequest.update(data.id, data)
      } else {
        await purchaseBatchDetailsRequest.add(data)
      }
      this.$emit('dataUpdate', 'add')
      this.modal = false
      this.loading = false
    },
    onChange (key) {
      if (isNaN(this.formData[key])) {
        this.$notify({
          title: '系统提示',
          message: '请输入数值',
          type: 'warning'
        })
        this.formData[key] = ''
        this.$set(this.formData, 'total', 0)
        return
      }
      if (this.formData.num && this.formData.price && !isNaN(this.formData.num) && !isNaN(this.formData.price)) {
        this.$set(this.formData, 'total', Number((this.formData.num * this.formData.price).toFixed(2)))
      } else {
        this.$set(this.formData, 'total', 0)
      }
    }
  }
}
</script>

<style scoped lang="less">
.p-detial-from {
  display: flex;
  height: 70vh;
  .center {
    max-height: 100%;
    overflow-y: auto;
    width: 380px;
    padding: 0 20px;
  }
  .left {
    flex: 1;
    border-radius: 5px;
  }
  .left-a {
    border: 1px solid #e8eaec;
    border-radius: 5px;
    flex: 1;
    display: flex;
  }
  .right {
    margin-right: 20px;
    width: 500px;
  }
  .budget-detail-list-search {
    margin-left: 10px;
  }
  .budget-detail-list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    max-height: calc(100% - 80px);
    overflow-y: auto;
  }
  .budget-detail-item {
    background: #e8eaec;
    color: #515a6e;
    line-height: 28px;
    padding: 0 10px;
    border-radius: 5px;
    margin: 10px 0 0 10px;
  }
  .budget-detail-item-a {
    background: #F4628F;
    color: #fff;
  }
}
.model-btn {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.xj {
  position: absolute;
  right: 20px;
  bottom: 0px;
  color: #F4628F;
}
</style>