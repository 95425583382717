<template>
  <div @click="$emit('clickItem', data)" class="sign-purchase-detail-item" :class="{'sign-purchase-detail-item-a': active}">
    <span>{{data.code + ' ' + (data.orgName || '') + ' ' + (data.goodsName || '')}}</span>
  </div>
</template>

<script>
export default {
  props: {
    data: { type: Object, defualt: () => null },
    active: { type: Boolean, defualt: false }
  }
}
</script>

<style lang="less" scoped>
.sign-purchase-detail-item {
  cursor: pointer;
  background: #e8eaec;
  padding: 0 10px;
  height: 28px;
  text-align: center;
  color: #515a6e;
  line-height: 28px;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.sign-purchase-detail-item-a {
    background: #F4628F;
    color: #fff;
  }
</style>