import sysEnv from '@/env/env'
import { createWorkbook, bookAppendSheet, aoaToSheet, downloadExcelWithStyle, setWorkbookBaseStyle, setWorksheetCellStyle, Colspan, colspan } from '@/components/excel/lib'
import { fileRequest } from '@/api'
import { loadResourcesByCache } from '@/components/base/file/libv2'

const titleName = sysEnv.title || '未配置单位名称'

export function purchaseExport(data, type = 'pdf') {
  if (type === 'pdf') {
    return purchasePdfExport(data)
  }

  const base = data.statusActionRecord.map(({ id, statusKey, remark, userName, createTime }) => {
    return { id, statusKey, remark, userName, createTime }
  }).sort((a, b) => a.id - b.id)

  const records = [
    { key: 'wait_1', 'label': "申请部门\n分管领导意见" },
    { key: 'wait_2', 'label': "对口职能部门\n核实情况" },
    { key: 'wait_3', 'label': "采购部门\n分管领导意见" },
    { key: 'wait_4', 'label': "单位审批意见" }
  ].map(item => {
    const res = base.filter(v => v.statusKey === item.key).pop()
    return {
      data: [item.label, '', res ? ([res.remark, '(', res.userName, res.createTime.substr(0, 10), ')']).join(' ') : '', '', ''],
      merges: [[0, 1], [2, 4]],
      rows: { hpx: 80 }
    }
  })

  const workbook = createWorkbook()
  const aoa = [
    {
      data: [titleName + '物品、设备采购（维保）申请单', ...colspan(4)],
      merges: [[0, 4]],
      rows: { hpx: 75 }
    },
    {
      data: ['申请部门：' + data.orgName + ' 申请人：' + data.userName + ' 申请时间：' + data.createTime.substr(0, 10), ...colspan(4)],
      merges: [[0, 4]]
    },
    {
      data: ['序号', '项目名称', '品牌及规格型号', '数量', '预估价格'],
      rows: { hpx: 40 }
    },
    {
      data: [1, data.goodsName, data.typeDetail, data.num, data.total]
    },
    {
      data: ['合计', ...colspan(1), '', data.num, data.total],
      merges: [[0, 1]],
      rows: { hpx: 45 }
    },
    {
      data: ["申请\n理由", data.reason, ...colspan(1), '部门负责人（签名）', data.userName],
      merges: [[1, 2]]
    },
    ...records,
    {
      data: ["备注：1、对口职能部门分别为：基建办、办公室、药械科、总务科等；\n2、2000元及以上项目需通过院长办公会审议后采购，经议价小组议价后总价低于5000元可直接支出，高于或等于5000元时须经院长办公会或支委会研究后支出。", ...colspan(4)],
      merges: [[0, 4]],
      rows: { hpx: 80 }
    }
  ]
  const worksheet = aoaToSheet(aoa.map(v => v.data))
  worksheet['!rows'] = {}

  const merges = []
  aoa.forEach((v, i) => {
    if (v.merges && Array.isArray(v.merges) && v.merges.length) {
      merges.push(...new Colspan(v.merges, i))
    }
    if (v.rows) {
      worksheet['!rows'][i] = v.rows
    }
  })
  worksheet['!merges'] = merges
  worksheet['!cols'] = [{ wpx: 50 }, { wpx: 152 }, { wpx: 152 }, { wpx: 100 }, { wpx: 100 }]

  bookAppendSheet(workbook, worksheet, '申请单')

  const emptyBorder = {
    top: { style: 'thin', color: { rgb: 'FFFFFF' } },
    bottom: { style: 'thin', color: { rgb: 'FFFFFF' } },
    left: { style: 'thin', color: { rgb: 'FFFFFF' } },
    right: { style: 'thin', color: { rgb: 'FFFFFF' } }
  }
  const blackBorder = {
    top: { style: 'thin', color: { rgb: '000000' } },
    bottom: { style: 'thin', color: { rgb: '000000' } },
    left: { style: 'thin', color: { rgb: '000000' } },
    right: { style: 'thin', color: { rgb: '000000' } }
  }

  // 设置表格基础样式
  setWorkbookBaseStyle(workbook, {
    font: {
      name: '宋体',
      sz: 14
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
      wrapText: true
    },
    border: blackBorder
  })

  // 表头
  setWorksheetCellStyle(worksheet, [
    ...new Array(5).fill(null).map((v, i) => [0, i]),
  ], {
    font: {
      bold: true,
      name: '宋体',
      sz: 18
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center',
      wrapText: true
    },
    border: emptyBorder
  })

  // 表格信息
  setWorksheetCellStyle(worksheet, [
    ...new Array(5).fill(null).map((v, i) => [1, i]),
  ], {
    border: emptyBorder
  })

  // 左对齐
  setWorksheetCellStyle(worksheet, [
    [5, 1], [5, 2], [aoa.length - 1, 0], [aoa.length - 1, 1], [aoa.length - 1, 2], [aoa.length - 1, 3], [aoa.length - 1, 4]
  ], {
    font: {
      name: '宋体',
      sz: 14
    },
    alignment: {
      horizontal: 'left',
      vertical: 'center',
      wrapText: true
    },
    border: blackBorder
  })

  console.log(worksheet)

  downloadExcelWithStyle(workbook, [data.year, data.orgName, data.goodsName].join('-'))
}

export async function purchasePdfExport (data) {
  const base = data.statusActionRecord.map(({ id, statusKey, statusActionLabel, remark, userName, createTime, userSignFileId }) => {
    return { id, statusKey, statusActionLabel, remark, userName, createTime, userSignFileId }
  }).sort((a, b) => a.id - b.id)
  
  const signFileIds = Array.from(new Set([
    ...base.map(v => v.userSignFileId),
    data.userSignFileId,
    data.orgChargeUserSignFileId
  ])).filter(v => v)
  const signFileData = signFileIds.length ? await fileRequest.getFileData({
    ids: signFileIds.join(',')
  }) : []
  const signFiles = await Promise.all(signFileData.map(async file => {
    const blob = await loadResourcesByCache({ file })
    return { id: file.id, blob }
  }))

  const records = [
    { key: 'wait_1', 'label': "申请部门<br />分管领导意见" },
    { key: 'wait_2', 'label': "对口职能部门<br />核实情况" },
    { key: 'wait_3', 'label': "采购部门<br />分管领导意见" },
    { key: 'wait_4', 'label': "单位审批意见" }
  ].map(item => {
    const res = base.filter(v => v.statusKey === item.key).pop()
    const sign = res ? signFiles.find(v => v.id === res.userSignFileId) : null
    return `
      <tr class="border">
        <td style="height: 90px" colspan="2">${item.label}</td>
        <td style="height: 90px" colspan="3">
          ${res ? (res.remark || res.statusActionLabel) : ''}<br />
          <div style="margin-top: 20px;justify-content: flex-end;display: flex;align-items: center;">
            签字：
            <span style="display: ${sign ? 'none' : 'inline-block'};min-width: 3cm;"></span>
            <img style="display: ${sign ? 'inline-block' : 'none'};height: 1cm;" src="${sign ? sign.blob : ''}" />
            <span style="display: inline-block;min-width: 3cm;">
              ${res ? res.createTime.substr(0, 10) : ''}
            </span>
          </div>
        </td>
      </tr>
    `
  }).join('')

  const applyUserSign = signFiles.find(v => v.id === data.userSignFileId)
  const applyUser = `
    <span style="display: ${applyUserSign ? 'none' : 'inline-block'};min-width: 3cm;"></span>
    <img style="display: ${applyUserSign ? 'inline-block' : 'none'};height: 1cm;" src="${applyUserSign ? applyUserSign.blob : ''}" />
  `

  const orgChargeUserSign = signFiles.find(v => v.id === data.orgChargeUserSignFileId)
  const orgChargeUser = `
    <span style="display: ${orgChargeUserSign ? 'none' : 'inline-block'};min-width: 3cm;"></span>
    <img style="display: ${orgChargeUserSign ? 'inline-block' : 'none'};height: 1cm;" src="${orgChargeUserSign ? orgChargeUserSign.blob : ''}" />
  `

  const id = new Date().getTime()+ '-' + parseInt(Math.random() * 100)
  const iframe = document.createElement('iframe')
  iframe.setAttribute('id', id)
  iframe.style.display = 'none'
  document.body.appendChild(iframe)
  const style = '<link rel="stylesheet" href="/static/document/table-print.css">'
  const print = '<script>document.addEventListener("DOMContentLoaded", function () {setTimeout(function () {window.print();setTimeout(function () {window.parent.document.getElementById("' + id + '").remove();}, 1000)}, 0);});</script>'
  const html = `
    <table class="single">
      <tr class="noborder">
        <td colspan="5" style="font-weight: bolder;font-size: 18px;line-height: 75px;">${titleName}物品、设备采购申请单</td>
      </tr>
      <tr class="noborder">
        <td style="border-bottom: 1px solid #000; text-align: left;" colspan="5">申请部门：${data.orgName || ''} 申请人：${applyUser} 申请时间：${data.createTime.substr(0, 10)}</td>
      <tr/>
      <tr class="border" style="border-top: 1px solid #000;line-height: 50px;">
        <td>序号</td>
        <td>项目名称</td>
        <td>品牌及规格型号</td>
        <td>数量</td>
        <td>预估价格</td>
      </tr>
      <tr class="border">
        <td>1</td>
        <td>${data.goodsName}</td>
        <td>${data.typeDetail || ''}</td>
        <td>${data.num}</td>
        <td>${data.total}元</td>
      </tr>
      <tr class="border" style="line-height: 40px;">
        <td colspan="2">合计</td>
        <td></td>
        <td>${data.num}</td>
        <td>${data.total}元</td>
      </tr>
      <tr class="border">
        <td>申请<br />理由</td>
        <td colspan="2" style="text-align: left">${data.reason || ''}</td>
        <td>部门负责人<br />（签名）</td>
        <td>${orgChargeUser}</td>
      </tr>
      ${records}
      <tr class="border">
        <td colspan="5" style="text-align: left; line-height: 2;">备注：1、对口职能部门分别为：基建办、办公室、药械科、总务科等；<br />2、2000元及以上项目需通过院长办公会审议后采购，经议价小组议价后总价低于5000元可直接支出，高于或等于5000元时须经院长办公会或支委会研究后支出。</td>
      </tr>
    </table>
  `
  iframe.srcdoc = '<!DOCTYPE html><head>' + style + '</head><body>' + html + '</body> ' + print + ' </html>'
}

export const detailFilter = (dataItem, parm) => {
  let pass = true
  if (!parm) {
    return pass
  }
  let keys = Object.keys(parm).filter(v => v != '__key')
  let i = 0
  while (pass && i < keys.length) {
    let key = keys[i]
    pass = dataItem[key] === parm[key]
    i += 1
  }
  return pass
}


export const getBatchInfo = (datas) => {
  if (!datas || datas.length === 0) {
    return null
  }
  let info = {
    startTime: null,
    endTime: null,
    cyOrgNum: 0,
    cyLeaderNum: 0,
    isEnd: true,
    fileIds: []
  }
  let cyOrgs = []
  let cyLeaders = []
  let i = 0
  while (i < datas.length) {
    let item = datas[i]
    info.isEnd = info.isEnd && item.status === 'end'
    if (item.fileIds) {
      info.fileIds.push(item.fileIds)
    }

    let orgIds = item.orgIds ? item.orgIds.split(',') : []
    orgIds.forEach(v => !cyOrgs.includes(v) ? cyOrgs.push(v) : null)

    let busOrgIds = item.busOrgIds ? item.busOrgIds.split(',') : []
    busOrgIds.forEach(v => !cyOrgs.includes(v) ? cyOrgs.push(v) : null)

    let leadUserIds = item.leadUserIds ? item.leadUserIds.split(',') : []
    leadUserIds.forEach(v => !cyLeaders.includes(v) ? cyLeaders.push(v) : null)
    
    let deanUserIds = item.deanUserIds ? item.deanUserIds.split(',') : []
    deanUserIds.forEach(v => !cyLeaders.includes(v) ? cyLeaders.push(v) : null)

    let purchaseLeadUserIds = item.purchaseLeadUserIds ? item.purchaseLeadUserIds.split(',') : []
    purchaseLeadUserIds.forEach(v => !cyLeaders.includes(v) ? cyLeaders.push(v) : null)

    if (item.statusActionRecord && item.statusActionRecord.length > 0) {
      let start = item.statusActionRecord.find(v => v.statusActionKey === 'creating_switch_doing' && !v.isBack)
      if (start) {
        if (info.startTime == null || (new Date(info.startTime).getTime() > new Date(start.createTime).getTime())) {
          info.startTime = start.createTime
        }
      }
      let end = item.statusActionRecord[item.statusActionRecord.length - 1]
      if (end.statusActionKey === 'doing_switch_end') {
        if (info.endTime == null || (new Date(info.endTime).getTime() < new Date(end.createTime).getTime())) {
          info.endTime = end.createTime
        }
      }
    }
    i += 1
  }
  if (!info.isEnd) {
    info.endTime = null
  }
  info.cyOrgNum = cyOrgs.length
  info.cyLeaderNum = cyLeaders.length
  info.fileIds = info.fileIds.join(',').split(',').map(v => Number(v))
  return info
}
