<template>
  <fm-modal title="提交" v-model="modal" width="600px" v-if="modal">
    <fm-form label-align="left">
      <fm-form-item label="申请部门分管领导" v-if="updateKeys && updateKeys.split(',').includes('leadUserId')">
        <fm-select absolute filterable :clearable="true" v-model="formData.leadUserId">
          <fm-option v-for="item in leadUserList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="科室负责人" v-if="updateKeys && updateKeys.split(',').includes('orgChargeUserId')">
        <fm-select absolute filterable :clearable="true" v-model="formData.orgChargeUserId">
          <fm-option v-for="item in orgChargeUserList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="对口职能部门" v-if="updateKeys && updateKeys.split(',').includes('busOrgId')">
        <fm-select absolute filterable :clearable="true" v-model="formData.busOrgId">
          <fm-option v-for="item in busOrgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="采购部门分管领导" v-if="updateKeys && updateKeys.split(',').includes('purchaseLeadUserId')">
        <fm-select absolute filterable :clearable="true" v-model="formData.purchaseLeadUserId">
          <fm-option v-for="item in purchaseLeadUserList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="院长" v-if="updateKeys && updateKeys.split(',').includes('deanUserId')">
        <fm-select absolute filterable :clearable="true" v-model="formData.deanUserId">
          <fm-option v-for="item in deanUserList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
    </fm-form>
    <div class="model-btn">
      <fm-btn style="margin-right: 30px;" @click="formSubmit">提交</fm-btn>
      <fm-btn @click="modal = false">取消</fm-btn>
    </div>
  </fm-modal>
</template>

<script>
export default {
  props: {
    updateKeys: { type: String, defualt: null },
    batchData: {
      type: Object
    }
  },
  data () {
    return {
      formData: {},
      modal: false
    }
  },
  computed: {
    // leadUserId busOrgId purchaseLeadUserId deanUserId
    leadUserList () {
      let leadUserIds = this.batchData && this.batchData.leadUserIds ? this.batchData.leadUserIds.split(',').map(v => Number(v)) : []
      return this.$store.getters.userList.filter(v => leadUserIds.includes(v.data.id))
    },
    orgChargeUserList () {
      let orgChargeUserIds = this.batchData && this.batchData.orgChargeUserIds ? this.batchData.orgChargeUserIds.split(',').map(v => Number(v)) : []
      return this.$store.getters.userList.filter(v => orgChargeUserIds.includes(v.data.id))
    },
    busOrgList () {
      let busOrgIds = this.batchData && this.batchData.busOrgIds ? this.batchData.busOrgIds.split(',').map(v => Number(v)) : []
      return this.$store.getters.orgList.filter(v => busOrgIds.includes(v.data.id))
    },
    purchaseLeadUserList () {
      let purchaseLeadUserIds = this.batchData && this.batchData.purchaseLeadUserIds ? this.batchData.purchaseLeadUserIds.split(',').map(v => Number(v)) : []
      return this.$store.getters.userList.filter(v => purchaseLeadUserIds.includes(v.data.id))
    },
    deanUserList () {
      let deanUserIds = this.batchData && this.batchData.deanUserIds ? this.batchData.deanUserIds.split(',').map(v => Number(v)) : []
      return this.$store.getters.userList.filter(v => deanUserIds.includes(v.data.id))
    }
  },
  methods: {
    async formSubmit () {
      let pass = true
      this.updateKeys.split(',').forEach(v => {
        if (!this.formData[v]) {
          pass = false
        }
      })
      if (!pass) {
        this.$notify({
          title: '系统提示',
          message: '请检查表单',
          type: 'info'
        })
      } else {
        this.$emit('submit', this.formData)
        this.modal = false
      }
    }
  }
}
</script>

<style scoped lang="less">
.model-btn {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>