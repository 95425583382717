<template>
  <div class="goods-select">
    <div class="goods-select-note">
      <div class="add-info-b info-b" v-if="!value">
        如下拉选项中无您所需项目名称，点击 <fm-btn size="small" @click="open">新增</fm-btn> 进行新增
      </div>
      <div class="edit-info-b info-b" v-else>
        如所选项目名称对应资产分类异常，点击 <fm-btn size="small" @click="open">修改</fm-btn> 按钮进行调整
      </div>
    </div>
    <fm-select
      absolute
      @change="change"
      :value="value"
      block
      :placeholder="placeholder || '请选择'"
      :disabled="disabled"
      filterable
      :clearable="clearable"
      :required="required"
      :rules="rules"
      v-verifier>
      <fm-option
        v-for="itemO in $store.getters.goodsList"
        :key="itemO.key"
        :label="itemO.label" :value="itemO.key">
      </fm-option>
    </fm-select>
    <fm-modal v-model="openEdit" width="800px" theme="mh-blackt" :mask-closable="false">
      <div style="width: 100%;height:70vh;" class="goods-form">
        <div class="goods-form-d">
          <fm-title title-text="项目"></fm-title>
          <fm-form :inline="false" label-align="left" label-alone>
            <fm-form-item label="名称">
              <fm-input-new
                :required="true"
                style="width: 100%;"
                v-model="formData.name"
                placeholder="名称"
              ></fm-input-new>
            </fm-form-item>
            <fm-form-item label="资产类型 (点击左侧资产分类中选择)">
              <fm-input-new
                :required="false"
                style="width: 100%;"
                v-model="formData.assetsTypeLabel"
                :disabled="true"
                placeholder="点击左侧资产分类中选择"
              ></fm-input-new>
            </fm-form-item>
            <div class="goods-form-btn">
              <fm-btn @click="save">保存</fm-btn>
              <fm-btn style="margin-left:3rem;" @click="openEdit = false">关闭</fm-btn>
            </div>
          </fm-form>
        </div>
        <div class="goods-form-a">
          <assets-type-tree :is-select="true" @change="assetsTypeChange"></assets-type-tree>
        </div>
      </div>
    </fm-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import AssetsTypeTree from './assetsTypeTree'

import {
  goodsRequest
} from '../../../api'

export default {
  name: 'GoodsSelect',
  components: {
    AssetsTypeTree
  },
  props: {
    value: Number,
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data () {
    return {
      onError: '',
      openEdit: false,
      formData: {
        name: '',
        assetsTypeName: null,
        assetsTypeCode: null,
        assetsTypeId: null,
        assetsTypeLabel: ''
      }
    }
  },
  methods: {
    async save () {
      if (!this.formData.name) {
        this.$notify({
          title: '系统提示',
          message: '请输入名称',
          type: 'info'
        })
        return
      }
      let parm = JSON.parse(JSON.stringify(this.formData))
      if (!parm.assetsTypeId) {
        parm.assetsTypeId = undefined
      }
      let isUpdate = false
      if (parm.id) {
        await goodsRequest.update(parm.id, parm)
        isUpdate = true
      } else {
        parm = await goodsRequest.add(parm)
      }
      this.$notify({
        title: '系统提示',
        message: '项目已新增',
        type: 'success'
      })
      await this.$store.dispatch('loadGoodsList', true)
      if (isUpdate) {
        this.change(null)
        let vm = this
        setTimeout(() => {
          vm.change(parm.id)
        }, 200)
      } else {
        this.change(parm.id)
      }
      this.openEdit = false
    },
    assetsTypeChange (data) {
      if (data) {
        this.$set(this.formData, 'assetsTypeName', data.name)
        this.$set(this.formData, 'assetsTypeCode', data.code)
        this.$set(this.formData, 'assetsTypeId', data.id)
        this.$set(this.formData, 'assetsTypeLabel', data.code + ' ' + data.name)
      } else {
        this.$set(this.formData, 'assetsTypeName', '')
        this.$set(this.formData, 'assetsTypeCode', '')
        this.$set(this.formData, 'assetsTypeId', '')
        this.$set(this.formData, 'assetsTypeLabel', '')
      }
    },
    open () {
      let data = this.$store.getters.goodsList.find(v => v.key === this.value)
      if (data) {
        this.$set(this.formData, 'id', data.data.id)
        this.$set(this.formData, 'name', data.data.name)
        this.$set(this.formData, 'assetsTypeName', data.data.assetsTypeName)
        this.$set(this.formData, 'assetsTypeCode', data.data.assetsTypeCode)
        this.$set(this.formData, 'assetsTypeId', data.data.assetsTypeId)
        this.$set(this.formData, 'assetsTypeLabel', data.data.assetsTypeId ? data.data.assetsTypeCode + ' ' + data.data.assetsTypeName : '')
      } else {
        this.$set(this.formData, 'id', undefined)
        this.$set(this.formData, 'name', '')
        this.$set(this.formData, 'assetsTypeName', '')
        this.$set(this.formData, 'assetsTypeCode', '')
        this.$set(this.formData, 'assetsTypeId', '')
        this.$set(this.formData, 'assetsTypeLabel', '')
      }
      this.openEdit = true
    },
    verifier () {
      this.$emit('verifier', '')
      this.onError = '' !== ''
      return ''
    },
    change (value) {
      this.modalAssetsType = false
      this.$emit('change', value)
      Vue.nextTick(() => {
        this.verifier()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.goods-form-btn {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
.goods-select {
  position: relative;
}
.goods-select-note {
  position: absolute;
  top: -36px;
  right: 0;
}
.goods-form {
  display: flex;
  height: 100%;
}
.goods-form-d {
  flex: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
}
.goods-form-a {
  flex: 3;
  height: 100%;
}
.info-b {
  padding: 0 16px;
  border-radius: 5px;
}
.edit-info-b {
  background: rgba(45, 183, 245, 0.3);
}
.add-info-b {
  background: rgba(25, 190, 107, 0.3);
}
</style>